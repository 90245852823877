export default function LargeText({
    children,
    className = '',
    isLoading,
    ...rest
}: { children?: React.ReactNode; isLoading?: boolean } & React.HTMLAttributes<HTMLParagraphElement>) {
    return (
        <p {...rest} className={`md:text-xl xs:text-lg text-base text-gray-900 ${className}`}>
            {children}
        </p>
    )
}
