/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingEntity } from './BookingEntity';
import type { DateTime } from './DateTime';
import type { EnabledClientNotificationSettingEntity } from './EnabledClientNotificationSettingEntity';
import type { TherapistClientLinkingEntity } from './TherapistClientLinkingEntity';
export type ClientEntity = {
    id: number;
    tebutoId: string;
    idpId: string;
    email: string;
    firstName: string;
    lastName: string;
    gender: ClientEntity.gender;
    termsAndConditionsAccepted: Record<string, any> | null;
    isActive: boolean;
    deleted: boolean;
    createdAt: DateTime;
    therapists: Array<TherapistClientLinkingEntity>;
    bookings: Array<BookingEntity>;
    enabledNotificationSettings: Array<EnabledClientNotificationSettingEntity>;
};
export namespace ClientEntity {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
}

