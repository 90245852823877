/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Client } from '../models/Client';
import type { ClientList } from '../models/ClientList';
import type { ClientWithBookings } from '../models/ClientWithBookings';
import type { InviteClientsPayload } from '../models/InviteClientsPayload';
import type { SortOptions } from '../models/SortOptions';
import type { UpdateClientPayload } from '../models/UpdateClientPayload';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ClientsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all clients
     * Get all clients
     * @param therapistId
     * @param search
     * @param sort
     * @returns ClientList
     * @throws ApiError
     */
    public getClients(
        therapistId: number,
        search?: string,
        sort?: SortOptions,
    ): CancelablePromise<ClientList> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/clients',
            path: {
                'therapistId': therapistId,
            },
            query: {
                'search': search,
                'sort': sort,
            },
        });
    }
    /**
     * Invite a client
     * Invite a client
     * @param therapistId
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public inviteClients(
        therapistId: number,
        requestBody: InviteClientsPayload,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/clients',
            path: {
                'therapistId': therapistId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get a client
     * Get a client
     * @param therapistId
     * @param clientId
     * @returns ClientWithBookings
     * @throws ApiError
     */
    public getClient(
        therapistId: number,
        clientId: number,
    ): CancelablePromise<ClientWithBookings> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{therapistId}/clients/{clientId}',
            path: {
                'therapistId': therapistId,
                'clientId': clientId,
            },
        });
    }
    /**
     * Update a client
     * Update a client
     * @param therapistId
     * @param clientId
     * @param requestBody
     * @returns Client
     * @throws ApiError
     */
    public updateClient(
        therapistId: number,
        clientId: number,
        requestBody: UpdateClientPayload,
    ): CancelablePromise<Client> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/therapists/{therapistId}/clients/{clientId}',
            path: {
                'therapistId': therapistId,
                'clientId': clientId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove a client
     * Remove a client
     * @param therapistId
     * @param clientId
     * @param cancelFutureBookings
     * @param deleteAllBookings
     * @returns void
     * @throws ApiError
     */
    public removeClient(
        therapistId: number,
        clientId: number,
        cancelFutureBookings?: boolean,
        deleteAllBookings?: boolean,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}/clients/{clientId}',
            path: {
                'therapistId': therapistId,
                'clientId': clientId,
            },
            query: {
                'cancelFutureBookings': cancelFutureBookings,
                'deleteAllBookings': deleteAllBookings,
            },
        });
    }
    /**
     * Resend a client invitation
     * Resend invitation
     * @param therapistId
     * @param invitedClientId
     * @returns any
     * @throws ApiError
     */
    public resendInvitation(
        therapistId: number,
        invitedClientId: number,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/therapists/{therapistId}/clients/invited/{invitedClientId}/resend',
            path: {
                'therapistId': therapistId,
                'invitedClientId': invitedClientId,
            },
        });
    }
    /**
     * Withdraw a client invitation
     * Withdraw invitation
     * @param therapistId
     * @param invitedClientId
     * @returns void
     * @throws ApiError
     */
    public withdrawInvitation(
        therapistId: number,
        invitedClientId: number,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/therapists/{therapistId}/clients/invited/{invitedClientId}',
            path: {
                'therapistId': therapistId,
                'invitedClientId': invitedClientId,
            },
        });
    }
}
