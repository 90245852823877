interface HeadingProps {
    level: number
    children?: React.ReactNode
    className?: string
}

export default function Heading({ level, children, className, ...rest }: HeadingProps & React.HTMLAttributes<HTMLHeadingElement>) {
    const baseCss = `font-medium hyphens-auto md:leading-10 tracking-tight w-full ${className ? className : ''}`

    switch (level) {
        case 1:
            return (
                <h1 className={`xl:text-4xl xs:text-3xl text-2xl ${baseCss}`} {...rest}>
                    {children}
                </h1>
            )
        case 2:
            return (
                <h2 className={`xl:text-3xl xs:text-2xl text-xl ${baseCss}`} {...rest}>
                    {children}
                </h2>
            )

        case 3:
            return (
                <h3 className={`xl:text-2xl xs:text-xl text-lg ${baseCss}`} {...rest}>
                    {children}
                </h3>
            )
        case 4:
            return (
                <h4 className={`xl:text-xl xs:text-lg text-base ${baseCss}`} {...rest}>
                    {children}
                </h4>
            )
        case 5:
            return (
                <h5 className={`xl:text-lg xs:text-base text-sm ${baseCss}`} {...rest}>
                    {children}
                </h5>
            )
    }
}
