/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export { TebutoApi } from './TebutoApi';

export { ApiError } from './core/ApiError';
export { BaseHttpRequest } from './core/BaseHttpRequest';
export { CancelablePromise, CancelError } from './core/CancelablePromise';
export { OpenAPI } from './core/OpenAPI';
export type { OpenAPIConfig } from './core/OpenAPI';

export type { Address as AddressAPIModel } from './models/Address';
export type { AddressEntity as AddressEntityAPIModel } from './models/AddressEntity';
export { AWSNotification as AWSNotificationAPIModel } from './models/AWSNotification';
export type { BookableEvent as BookableEventAPIModel } from './models/BookableEvent';
export { BookedClientEvent as BookedClientEventAPIModel } from './models/BookedClientEvent';
export { BookedEvent as BookedEventAPIModel } from './models/BookedEvent';
export { BookEventForCustomerPayload as BookEventForCustomerPayloadAPIModel } from './models/BookEventForCustomerPayload';
export { BookExistingEventPayload as BookExistingEventPayloadAPIModel } from './models/BookExistingEventPayload';
export { BookGeneratedEventPayload as BookGeneratedEventPayloadAPIModel } from './models/BookGeneratedEventPayload';
export { Booking as BookingAPIModel } from './models/Booking';
export { BookingEntity as BookingEntityAPIModel } from './models/BookingEntity';
export { BookingWithoutEvent as BookingWithoutEventAPIModel } from './models/BookingWithoutEvent';
export type { Calendar as CalendarAPIModel } from './models/Calendar';
export { CalendarAccount as CalendarAccountAPIModel } from './models/CalendarAccount';
export type { CalendarEntity as CalendarEntityAPIModel } from './models/CalendarEntity';
export type { CalendarExport as CalendarExportAPIModel } from './models/CalendarExport';
export type { CalendarExportEntity as CalendarExportEntityAPIModel } from './models/CalendarExportEntity';
export { CalendarProvider as CalendarProviderAPIModel } from './models/CalendarProvider';
export type { CancelBookingByClientPayload as CancelBookingByClientPayloadAPIModel } from './models/CancelBookingByClientPayload';
export type { CancelBookingByTherapistPayload as CancelBookingByTherapistPayloadAPIModel } from './models/CancelBookingByTherapistPayload';
export type { CaptchaChallenge as CaptchaChallengeAPIModel } from './models/CaptchaChallenge';
export { Characteristic as CharacteristicAPIModel } from './models/Characteristic';
export type { CheckEventMoveOptionsPayload as CheckEventMoveOptionsPayloadAPIModel } from './models/CheckEventMoveOptionsPayload';
export type { CheckEventMoveOptionsResultPayload as CheckEventMoveOptionsResultPayloadAPIModel } from './models/CheckEventMoveOptionsResultPayload';
export type { CheckEventRuleMoveOptionsPayload as CheckEventRuleMoveOptionsPayloadAPIModel } from './models/CheckEventRuleMoveOptionsPayload';
export type { CheckEventRuleMoveOptionsResultPayload as CheckEventRuleMoveOptionsResultPayloadAPIModel } from './models/CheckEventRuleMoveOptionsResultPayload';
export type { CheckoutSessionData as CheckoutSessionDataAPIModel } from './models/CheckoutSessionData';
export { City as CityAPIModel } from './models/City';
export { CityEntity as CityEntityAPIModel } from './models/CityEntity';
export { CityWithTherapists as CityWithTherapistsAPIModel } from './models/CityWithTherapists';
export { Client as ClientAPIModel } from './models/Client';
export { ClientBooking as ClientBookingAPIModel } from './models/ClientBooking';
export { ClientEntity as ClientEntityAPIModel } from './models/ClientEntity';
export { ClientEvent as ClientEventAPIModel } from './models/ClientEvent';
export type { ClientList as ClientListAPIModel } from './models/ClientList';
export { ClientNotificationSetting as ClientNotificationSettingAPIModel } from './models/ClientNotificationSetting';
export { ClientRegistrationActionPayload as ClientRegistrationActionPayloadAPIModel } from './models/ClientRegistrationActionPayload';
export { ClientWithBookings as ClientWithBookingsAPIModel } from './models/ClientWithBookings';
export type { CodeLoginPayload as CodeLoginPayloadAPIModel } from './models/CodeLoginPayload';
export { ConfigurationPreconditionPayload as ConfigurationPreconditionPayloadAPIModel } from './models/ConfigurationPreconditionPayload';
export { ConnectThirdPartyAccountPayload as ConnectThirdPartyAccountPayloadAPIModel } from './models/ConnectThirdPartyAccountPayload';
export type { ContactFormPayload as ContactFormPayloadAPIModel } from './models/ContactFormPayload';
export { ContactInfo as ContactInfoAPIModel } from './models/ContactInfo';
export { ContactInfoEntity as ContactInfoEntityAPIModel } from './models/ContactInfoEntity';
export { ContactInformationPayload as ContactInformationPayloadAPIModel } from './models/ContactInformationPayload';
export { Contract as ContractAPIModel } from './models/Contract';
export { ContractEntity as ContractEntityAPIModel } from './models/ContractEntity';
export { CreateCharacteristicPayload as CreateCharacteristicPayloadAPIModel } from './models/CreateCharacteristicPayload';
export { CreateCheckoutSessionPayload as CreateCheckoutSessionPayloadAPIModel } from './models/CreateCheckoutSessionPayload';
export type { CreateFeatureCommentPayload as CreateFeatureCommentPayloadAPIModel } from './models/CreateFeatureCommentPayload';
export type { CreateFeatureRequestPayload as CreateFeatureRequestPayloadAPIModel } from './models/CreateFeatureRequestPayload';
export type { CreatePortalSessionPayload as CreatePortalSessionPayloadAPIModel } from './models/CreatePortalSessionPayload';
export { CustomNotificationTemplateEntity as CustomNotificationTemplateEntityAPIModel } from './models/CustomNotificationTemplateEntity';
export type { DateTime as DateTimeAPIModel } from './models/DateTime';
export { EnabledClientNotificationSettingEntity as EnabledClientNotificationSettingEntityAPIModel } from './models/EnabledClientNotificationSettingEntity';
export { EnabledUserNotificationSettingEntity as EnabledUserNotificationSettingEntityAPIModel } from './models/EnabledUserNotificationSettingEntity';
export { EventCategory as EventCategoryAPIModel } from './models/EventCategory';
export { EventCategoryEntity as EventCategoryEntityAPIModel } from './models/EventCategoryEntity';
export { EventCategoryPayload as EventCategoryPayloadAPIModel } from './models/EventCategoryPayload';
export { EventEntity as EventEntityAPIModel } from './models/EventEntity';
export type { EventOverview as EventOverviewAPIModel } from './models/EventOverview';
export type { EventRule as EventRuleAPIModel } from './models/EventRule';
export { EventRuleEntity as EventRuleEntityAPIModel } from './models/EventRuleEntity';
export { EventRulePayload as EventRulePayloadAPIModel } from './models/EventRulePayload';
export type { ExtendedTherapistSettings as ExtendedTherapistSettingsAPIModel } from './models/ExtendedTherapistSettings';
export { Feature as FeatureAPIModel } from './models/Feature';
export type { FeatureComment as FeatureCommentAPIModel } from './models/FeatureComment';
export type { FeatureCommentEntity as FeatureCommentEntityAPIModel } from './models/FeatureCommentEntity';
export { FeatureRequestEntity as FeatureRequestEntityAPIModel } from './models/FeatureRequestEntity';
export type { FeatureUser as FeatureUserAPIModel } from './models/FeatureUser';
export type { FeatureVoteEntity as FeatureVoteEntityAPIModel } from './models/FeatureVoteEntity';
export { FileEntity as FileEntityAPIModel } from './models/FileEntity';
export { FileWithUrl as FileWithUrlAPIModel } from './models/FileWithUrl';
export type { FulfillPreconditionPayload as FulfillPreconditionPayloadAPIModel } from './models/FulfillPreconditionPayload';
export { Health as HealthAPIModel } from './models/Health';
export type { ImportPlacePayload as ImportPlacePayloadAPIModel } from './models/ImportPlacePayload';
export type { InviteClientsPayload as InviteClientsPayloadAPIModel } from './models/InviteClientsPayload';
export type { InvitedClient as InvitedClientAPIModel } from './models/InvitedClient';
export type { InvitedClientEntity as InvitedClientEntityAPIModel } from './models/InvitedClientEntity';
export { LanguageOption as LanguageOptionAPIModel } from './models/LanguageOption';
export type { LeaveTherapistRequestPayload as LeaveTherapistRequestPayloadAPIModel } from './models/LeaveTherapistRequestPayload';
export type { LinkActionResponse as LinkActionResponseAPIModel } from './models/LinkActionResponse';
export { LinkData as LinkDataAPIModel } from './models/LinkData';
export { MinimalBooking as MinimalBookingAPIModel } from './models/MinimalBooking';
export { MinimalCity as MinimalCityAPIModel } from './models/MinimalCity';
export { MinimalClient as MinimalClientAPIModel } from './models/MinimalClient';
export type { MinimalEventCategory as MinimalEventCategoryAPIModel } from './models/MinimalEventCategory';
export { MinimalEventWithCategory as MinimalEventWithCategoryAPIModel } from './models/MinimalEventWithCategory';
export { MinimalPublicTherapist as MinimalPublicTherapistAPIModel } from './models/MinimalPublicTherapist';
export { MinimalPublicTherapistWithCity as MinimalPublicTherapistWithCityAPIModel } from './models/MinimalPublicTherapistWithCity';
export { MinimalUser as MinimalUserAPIModel } from './models/MinimalUser';
export type { MinimalWhoAmITherapist as MinimalWhoAmITherapistAPIModel } from './models/MinimalWhoAmITherapist';
export { NotificationTemplate as NotificationTemplateAPIModel } from './models/NotificationTemplate';
export { OnlineMeeting as OnlineMeetingAPIModel } from './models/OnlineMeeting';
export { OnlineMeetingEntity as OnlineMeetingEntityAPIModel } from './models/OnlineMeetingEntity';
export { Opening as OpeningAPIModel } from './models/Opening';
export { OpeningEntity as OpeningEntityAPIModel } from './models/OpeningEntity';
export { OpeningPayload as OpeningPayloadAPIModel } from './models/OpeningPayload';
export type { PagniatedMinimalPublicTherapistWithCity as PagniatedMinimalPublicTherapistWithCityAPIModel } from './models/PagniatedMinimalPublicTherapistWithCity';
export type { PaymentInformationEntity as PaymentInformationEntityAPIModel } from './models/PaymentInformationEntity';
export type { PaymentWebhookPayload as PaymentWebhookPayloadAPIModel } from './models/PaymentWebhookPayload';
export type { PaymentWebhookPayloadData as PaymentWebhookPayloadDataAPIModel } from './models/PaymentWebhookPayloadData';
export type { Place as PlaceAPIModel } from './models/Place';
export type { PlaceAddress as PlaceAddressAPIModel } from './models/PlaceAddress';
export { Placeholder as PlaceholderAPIModel } from './models/Placeholder';
export type { PlaceLocation as PlaceLocationAPIModel } from './models/PlaceLocation';
export type { PlaceOpening as PlaceOpeningAPIModel } from './models/PlaceOpening';
export { PublicTherapist as PublicTherapistAPIModel } from './models/PublicTherapist';
export type { PublicTherapistSettings as PublicTherapistSettingsAPIModel } from './models/PublicTherapistSettings';
export type { RejectBookingPayload as RejectBookingPayloadAPIModel } from './models/RejectBookingPayload';
export type { ReportPayload as ReportPayloadAPIModel } from './models/ReportPayload';
export type { RequestEmailChangePayload as RequestEmailChangePayloadAPIModel } from './models/RequestEmailChangePayload';
export type { RequestLoginCodePayload as RequestLoginCodePayloadAPIModel } from './models/RequestLoginCodePayload';
export type { ReserveEventPayload as ReserveEventPayloadAPIModel } from './models/ReserveEventPayload';
export type { SearchPlacePayload as SearchPlacePayloadAPIModel } from './models/SearchPlacePayload';
export type { SearchPlaceResult as SearchPlaceResultAPIModel } from './models/SearchPlaceResult';
export { SelectedCharacteristic as SelectedCharacteristicAPIModel } from './models/SelectedCharacteristic';
export { SelectPlanPreconditionPayload as SelectPlanPreconditionPayloadAPIModel } from './models/SelectPlanPreconditionPayload';
export { SetOutageFeeAppliesPayload as SetOutageFeeAppliesPayloadAPIModel } from './models/SetOutageFeeAppliesPayload';
export { SortOptions as SortOptionsAPIModel } from './models/SortOptions';
export type { Spatial as SpatialAPIModel } from './models/Spatial';
export type { SpatialEntity as SpatialEntityAPIModel } from './models/SpatialEntity';
export type { SupportRequestPayload as SupportRequestPayloadAPIModel } from './models/SupportRequestPayload';
export type { TebutoRuleEvent as TebutoRuleEventAPIModel } from './models/TebutoRuleEvent';
export type { TebutoStandaloneEvent as TebutoStandaloneEventAPIModel } from './models/TebutoStandaloneEvent';
export { Therapist as TherapistAPIModel } from './models/Therapist';
export { TherapistCharacteristic as TherapistCharacteristicAPIModel } from './models/TherapistCharacteristic';
export { TherapistCharacteristicEntity as TherapistCharacteristicEntityAPIModel } from './models/TherapistCharacteristicEntity';
export type { TherapistClientLinkingEntity as TherapistClientLinkingEntityAPIModel } from './models/TherapistClientLinkingEntity';
export { TherapistEntity as TherapistEntityAPIModel } from './models/TherapistEntity';
export type { TherapistInformationOption as TherapistInformationOptionAPIModel } from './models/TherapistInformationOption';
export { TherapistInformationOptionEntity as TherapistInformationOptionEntityAPIModel } from './models/TherapistInformationOptionEntity';
export { TherapistLanguage as TherapistLanguageAPIModel } from './models/TherapistLanguage';
export { TherapistLanguageEntity as TherapistLanguageEntityAPIModel } from './models/TherapistLanguageEntity';
export { TherapistLink as TherapistLinkAPIModel } from './models/TherapistLink';
export { TherapistLinkEntity as TherapistLinkEntityAPIModel } from './models/TherapistLinkEntity';
export type { TherapistPrecondition as TherapistPreconditionAPIModel } from './models/TherapistPrecondition';
export { TherapistPreconditionEntity as TherapistPreconditionEntityAPIModel } from './models/TherapistPreconditionEntity';
export type { TherapistSettings as TherapistSettingsAPIModel } from './models/TherapistSettings';
export type { TherapistSettingsEntity as TherapistSettingsEntityAPIModel } from './models/TherapistSettingsEntity';
export { TherapistUserLinkingEntity as TherapistUserLinkingEntityAPIModel } from './models/TherapistUserLinkingEntity';
export { TherapistUserLinkingWithUserInfo as TherapistUserLinkingWithUserInfoAPIModel } from './models/TherapistUserLinkingWithUserInfo';
export { ThirdPartyAccount as ThirdPartyAccountAPIModel } from './models/ThirdPartyAccount';
export { ThirdPartyAccountEntity as ThirdPartyAccountEntityAPIModel } from './models/ThirdPartyAccountEntity';
export { ThirdPartyAccountType as ThirdPartyAccountTypeAPIModel } from './models/ThirdPartyAccountType';
export type { ThirdPartyEvent as ThirdPartyEventAPIModel } from './models/ThirdPartyEvent';
export { ToggleClientNotification as ToggleClientNotificationAPIModel } from './models/ToggleClientNotification';
export { ToggleUserNotification as ToggleUserNotificationAPIModel } from './models/ToggleUserNotification';
export type { UpdateAddressPayload as UpdateAddressPayloadAPIModel } from './models/UpdateAddressPayload';
export type { UpdateCalendarPayload as UpdateCalendarPayloadAPIModel } from './models/UpdateCalendarPayload';
export { UpdateCharacteristicPayload as UpdateCharacteristicPayloadAPIModel } from './models/UpdateCharacteristicPayload';
export type { UpdateClientPayload as UpdateClientPayloadAPIModel } from './models/UpdateClientPayload';
export type { UpdateContactInformationPayload as UpdateContactInformationPayloadAPIModel } from './models/UpdateContactInformationPayload';
export type { UpdateCustomNotificationTemplate as UpdateCustomNotificationTemplateAPIModel } from './models/UpdateCustomNotificationTemplate';
export type { UpdateEventPayload as UpdateEventPayloadAPIModel } from './models/UpdateEventPayload';
export type { UpdateOpeningsPayload as UpdateOpeningsPayloadAPIModel } from './models/UpdateOpeningsPayload';
export { UpdateOwnClientPayload as UpdateOwnClientPayloadAPIModel } from './models/UpdateOwnClientPayload';
export type { UpdatePasswordPayload as UpdatePasswordPayloadAPIModel } from './models/UpdatePasswordPayload';
export { UpdateTherapistPayload as UpdateTherapistPayloadAPIModel } from './models/UpdateTherapistPayload';
export { UpdateUserPayload as UpdateUserPayloadAPIModel } from './models/UpdateUserPayload';
export { User as UserAPIModel } from './models/User';
export { UserEntity as UserEntityAPIModel } from './models/UserEntity';
export { UserNotificationSetting as UserNotificationSettingAPIModel } from './models/UserNotificationSetting';
export type { UserProfilePictureUploadResponse as UserProfilePictureUploadResponseAPIModel } from './models/UserProfilePictureUploadResponse';
export { WhoAmI as WhoAmIAPIModel } from './models/WhoAmI';
export { WhoAmIClient as WhoAmIClientAPIModel } from './models/WhoAmIClient';
export type { WhoAmITherapist as WhoAmITherapistAPIModel } from './models/WhoAmITherapist';
export { WhoAmITherapistUserLinking as WhoAmITherapistUserLinkingAPIModel } from './models/WhoAmITherapistUserLinking';
export type { ZoomWebhookDeauthorizeEventPayload as ZoomWebhookDeauthorizeEventPayloadAPIModel } from './models/ZoomWebhookDeauthorizeEventPayload';
export { ZoomWebhookEvent as ZoomWebhookEventAPIModel } from './models/ZoomWebhookEvent';

export { AddressService } from './services/AddressService';
export { AuthService } from './services/AuthService';
export { BookingsService } from './services/BookingsService';
export { CalendarExportService } from './services/CalendarExportService';
export { CalendarsService } from './services/CalendarsService';
export { CharacteristicsService } from './services/CharacteristicsService';
export { ClientsService } from './services/ClientsService';
export { ContactInformationService } from './services/ContactInformationService';
export { EventCategoriesService } from './services/EventCategoriesService';
export { EventRulesService } from './services/EventRulesService';
export { EventsService } from './services/EventsService';
export { ExportService } from './services/ExportService';
export { FeaturesService } from './services/FeaturesService';
export { FilesService } from './services/FilesService';
export { HealthService } from './services/HealthService';
export { LanguagesService } from './services/LanguagesService';
export { NotificationsService } from './services/NotificationsService';
export { OpeningsService } from './services/OpeningsService';
export { PaymentService } from './services/PaymentService';
export { PlacesService } from './services/PlacesService';
export { PreconditionsService } from './services/PreconditionsService';
export { SettingsService } from './services/SettingsService';
export { SupportService } from './services/SupportService';
export { TherapistsService } from './services/TherapistsService';
export { ThirdPartyAccountsService } from './services/ThirdPartyAccountsService';
export { UsersService } from './services/UsersService';
export { WhoAmIService } from './services/WhoAmIService';
