/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Placeholder = {
    name: string;
    type: Placeholder.type;
    example?: string;
    title?: string;
    key: string;
};
export namespace Placeholder {
    export enum type {
        TEXT = 'text',
        LINK = 'link',
        OPTIONAL = 'optional',
        LIST = 'list',
    }
}

