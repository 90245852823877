/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ClientEntity } from './ClientEntity';
export type EnabledClientNotificationSettingEntity = {
    notification: EnabledClientNotificationSettingEntity.notification;
    channel: EnabledClientNotificationSettingEntity.channel;
    client: ClientEntity;
    clientId: number;
};
export namespace EnabledClientNotificationSettingEntity {
    export enum notification {
        CLIENT_APPOINTMENT_REMINDERS = 'client-appointment-reminders',
        CLIENT_BOOKING_SUCCESS = 'client-booking-success',
        CLIENT_BOOKING_REJECTION = 'client-booking-rejection',
        CLIENT_BOOKING_CONFIRMATION = 'client-booking-confirmation',
        CLIENT_BOOKING_CANCELLATION = 'client-booking-cancellation',
        CLIENT_BOOKING_CANCELLED_BY_CLIENT = 'client-booking-cancelled-by-client',
    }
    export enum channel {
        EMAIL = 'email',
    }
}

