/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type MinimalClient = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    gender: MinimalClient.gender;
    deleted: boolean;
};
export namespace MinimalClient {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
}

