import { EventRule } from '@tebuto/api/clients/models/EventRule'
import { BookedEvent } from '@tebuto/api/therapists/models/BookedEvent'
import { City } from '@tebuto/api/therapists/models/City'
import { EventCategory } from '@tebuto/api/therapists/models/EventCategory'
import { OnlineMeeting } from '@tebuto/api/therapists/models/OnlineMeeting'
import { Opening } from '@tebuto/api/therapists/models/Opening'
import { TebutoRuleEvent } from '@tebuto/api/therapists/models/TebutoRuleEvent'
import { TebutoStandaloneEvent } from '@tebuto/api/therapists/models/TebutoStandaloneEvent'
import { ThirdPartyEvent } from '@tebuto/api/therapists/models/ThirdPartyEvent'
import COMPONENT_TEXTS from '@tebuto/translations'
import { BusinessHoursInput } from 'fullcalendar'
import { DateTime } from 'luxon'

export interface FullCalendarEvent<T = any> {
    title: string
    start: Date
    end: Date
    allDay: boolean
    extendedProps: T
}

export type EventWithType = ParsedTebutoRuleEvent | ParsedTebutoStandaloneEvent | ParsedThirdPartyEvent
export type FullCalendarEventWithType = FullCalendarEvent<EventWithType>

interface BaseEvent {
    title: string
    start: DateTime<true>
    end: DateTime<true>
    color: string
    allDay: boolean
}

export interface ParsedTebutoRuleEvent extends BaseEvent {
    type: 'rule'
    skipped: boolean
    rule: EventRule
    category: EventCategory
    details?: BookedEvent
}

export interface ParsedTebutoStandaloneEvent extends BaseEvent {
    type: 'standalone'
    id: number
    details: BookedEvent
    rule?: EventRule
    category?: EventCategory
}

export interface ParsedThirdPartyEvent extends BaseEvent {
    type: 'third-party'
    id: string
    description?: string
    calendarId: string
    isBlocking: boolean
}

export function convertRuleEventToFullCalendarEvent(
    event: TebutoRuleEvent,
    categories: Record<number, EventCategory>,
    rules: EventRule[]
): FullCalendarEvent<ParsedTebutoRuleEvent> | null {
    const category = categories[event.categoryId]
    const rule = rules.find(rule => rule.id === event.ruleId)
    if (!rule) {
        return null
    }

    const start = DateTime.fromISO(event.start)
    const end = DateTime.fromISO(event.end)

    return {
        title: event.title,
        start: start.toJSDate(),
        end: end.toJSDate(),
        allDay: false,
        backgroundColor: category.color,
        durationEditable: false,
        extendedProps: {
            type: 'rule',
            start,
            end,
            rule,
            category,
            allDay: false,
            title: event.title,
            color: category.color,
            details: event.details,
            skipped: event.skipped
        }
    } as FullCalendarEvent<ParsedTebutoRuleEvent>
}

export function convertStandaloneEventToFullCalendarEvent(
    event: TebutoStandaloneEvent,
    categories: Record<number, EventCategory>,
    rules: EventRule[]
): FullCalendarEvent<ParsedTebutoStandaloneEvent> {
    const category = event.categoryId ? categories[event.categoryId] : undefined
    const rule = rules.find(rule => rule.id === event.ruleId)
    const start = DateTime.fromISO(event.start)
    const end = DateTime.fromISO(event.end)
    const color = category ? category.color : '#009087'
    return {
        title: event.title,
        start: start.toJSDate(),
        end: end.toJSDate(),
        allDay: false,
        backgroundColor: color,
        editable: true,
        durationEditable: true,
        extendedProps: {
            type: 'standalone',
            id: event.id,
            title: event.title,
            start,
            end,
            color,
            allDay: false,
            details: event.details,
            category,
            rule
        }
    } as FullCalendarEvent<ParsedTebutoStandaloneEvent>
}

export function convertThirdPartyEventToFullCalendarEvent(event: ThirdPartyEvent): FullCalendarEvent<ParsedThirdPartyEvent> {
    const start = DateTime.fromISO(event.start)
    const end = DateTime.fromISO(event.end)
    return {
        title: event.title,
        start: start.toJSDate(),
        end: end.toJSDate(),
        allDay: event.allDay,
        backgroundColor: event.color,
        editable: false,
        durationEditable: false,
        show: 'background',
        extendedProps: {
            type: 'third-party',
            id: event.id,
            title: event.title,
            start,
            end,
            color: event.color,
            allDay: event.allDay,
            isBlocking: event.isBlocking,
            description: event.description,
            calendarId: event.calendarId
        }
    } as FullCalendarEvent<ParsedThirdPartyEvent>
}

export function getMinimumStartTime(openings: Opening[], extraHour = false): string {
    let minimumStartTime: DateTime | null = null

    for (const opening of openings) {
        const startTime = DateTime.fromISO(`1970-01-01T${opening.startTime}`)
        if (!minimumStartTime || startTime < minimumStartTime) {
            minimumStartTime = startTime
        }
    }

    if (minimumStartTime) {
        if (extraHour) {
            minimumStartTime = minimumStartTime.minus({ minutes: 30 })
        }
        return minimumStartTime.toFormat('HH:mm')
    }

    return '06:00'
}

export function getMaximumEndTime(openings: Opening[], extraHour = false): string {
    let maximumEndTime: DateTime | null = null

    for (const opening of openings) {
        const endTime = DateTime.fromISO(`1970-01-01T${opening.endTime}`)
        if (!maximumEndTime || endTime > maximumEndTime) {
            maximumEndTime = endTime
        }
    }

    if (maximumEndTime) {
        if (extraHour && !(maximumEndTime.hour === 23 && maximumEndTime.minute === 30)) {
            maximumEndTime = maximumEndTime.plus({ minutes: 30 })
        }
        return maximumEndTime.toFormat('HH:mm')
    }

    return '23:59'
}

export function getBusinessHours(openings: Opening[]): BusinessHoursInput {
    return [0, 1, 2, 3, 4, 5, 6]
        .map(day => openings.filter(opening => getDayNumber(opening.day) === day))
        .map((openingsForDay, index) => {
            if (openingsForDay.length > 0) {
                return {
                    daysOfWeek: [index],
                    startTime: getMinimumStartTime(openingsForDay),
                    endTime: getMaximumEndTime(openingsForDay)
                }
            }
        })
        .filter(opening => opening)
}

export function getDayNumber(day: Opening.day): number {
    switch (day) {
        case Opening.day.MONDAY:
            return 1
        case Opening.day.TUESDAY:
            return 2
        case Opening.day.WEDNESDAY:
            return 3
        case Opening.day.THURSDAY:
            return 4
        case Opening.day.FRIDAY:
            return 5
        case Opening.day.SATURDAY:
            return 6
        case Opening.day.SUNDAY:
            return 0
    }
}

export function getDayByNumber(day: number): Opening.day {
    switch (day) {
        case 1:
            return Opening.day.MONDAY
        case 2:
            return Opening.day.TUESDAY
        case 3:
            return Opening.day.WEDNESDAY
        case 4:
            return Opening.day.THURSDAY
        case 5:
            return Opening.day.FRIDAY
        case 6:
            return Opening.day.SATURDAY
        case 0:
            return Opening.day.SUNDAY
        default:
            throw new Error('Invalid day number')
    }
}

export function getDayTitle(day: Opening.day): string {
    switch (day) {
        case Opening.day.MONDAY:
            return COMPONENT_TEXTS.functions.days.monday
        case Opening.day.TUESDAY:
            return COMPONENT_TEXTS.functions.days.tuesday
        case Opening.day.WEDNESDAY:
            return COMPONENT_TEXTS.functions.days.wednesday
        case Opening.day.THURSDAY:
            return COMPONENT_TEXTS.functions.days.thursday
        case Opening.day.FRIDAY:
            return COMPONENT_TEXTS.functions.days.friday
        case Opening.day.SATURDAY:
            return COMPONENT_TEXTS.functions.days.saturday
        case Opening.day.SUNDAY:
            return COMPONENT_TEXTS.functions.days.sunday
        default:
            return ''
    }
}

export function getDayTitleByStart(date: DateTime<true>): string {
    switch (date.weekday) {
        case 1:
            return COMPONENT_TEXTS.functions.days.monday
        case 2:
            return COMPONENT_TEXTS.functions.days.tuesday
        case 3:
            return COMPONENT_TEXTS.functions.days.wednesday
        case 4:
            return COMPONENT_TEXTS.functions.days.thursday
        case 5:
            return COMPONENT_TEXTS.functions.days.friday
        case 6:
            return COMPONENT_TEXTS.functions.days.saturday
        case 7:
            return COMPONENT_TEXTS.functions.days.sunday
        default:
            return ''
    }
}

export function getMeetingProviderLabel(provider?: OnlineMeeting.provider) {
    switch (provider) {
        case OnlineMeeting.provider.ZOOM:
            return COMPONENT_TEXTS.functions.meetingProviders.zoom
        case OnlineMeeting.provider.MICROSOFT_TEAMS:
            return COMPONENT_TEXTS.functions.meetingProviders.microsoftTeams
        default:
            return 'Unbekannt'
    }
}

export function getFederalStateName(federalStateCode: City.federalStateCode): string {
    switch (federalStateCode) {
        case City.federalStateCode.BW:
            return 'Baden-Württemberg'
        case City.federalStateCode.BY:
            return 'Bayern'
        case City.federalStateCode.BE:
            return 'Berlin'
        case City.federalStateCode.BB:
            return 'Brandenburg'
        case City.federalStateCode.HB:
            return 'Bremen'
        case City.federalStateCode.HH:
            return 'Hamburg'
        case City.federalStateCode.HE:
            return 'Hessen'
        case City.federalStateCode.MV:
            return 'Mecklenburg-Vorpommern'
        case City.federalStateCode.NI:
            return 'Niedersachsen'
        case City.federalStateCode.NW:
            return 'Nordrhein-Westfalen'
        case City.federalStateCode.RP:
            return 'Rheinland-Pfalz'
        case City.federalStateCode.SL:
            return 'Saarland'
        case City.federalStateCode.SN:
            return 'Sachsen'
        case City.federalStateCode.ST:
            return 'Sachsen-Anhalt'
        case City.federalStateCode.SH:
            return 'Schleswig-Holstein'
        case City.federalStateCode.TH:
            return 'Thüringen'
    }
}

export function showRule(event: ParsedTebutoRuleEvent | ParsedTebutoStandaloneEvent): boolean {
    if (!event.rule || event.rule.deleted) {
        return false
    }

    const start = DateTime.fromISO(event.rule.start) as DateTime<true>
    return start.weekday === event.start.weekday && start.hour === event.start.hour && start.minute === event.start.minute
}
