/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ZoomWebhookDeauthorizeEventPayload } from './ZoomWebhookDeauthorizeEventPayload';
export type ZoomWebhookEvent = {
    event: ZoomWebhookEvent.event;
    event_ts: number;
    payload: ZoomWebhookDeauthorizeEventPayload;
};
export namespace ZoomWebhookEvent {
    export enum event {
        APP_DEAUTHORIZED = 'app_deauthorized',
    }
}

