/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Client = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    gender: Client.gender;
    isActive: boolean;
    deleted: boolean;
    notes: string | null;
};
export namespace Client {
    export enum gender {
        MALE = 'male',
        FEMALE = 'female',
        DIVERSE = 'diverse',
        NOT_SET = 'not_set',
        DELETED = 'deleted',
    }
}

