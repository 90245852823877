/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CalendarProvider } from '../models/CalendarProvider';
import type { ZoomWebhookEvent } from '../models/ZoomWebhookEvent';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CalendarsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Add Calendar Account Callback
     * Add Calendar Account Callback
     * @param code
     * @param state
     * @param provider
     * @returns any
     * @throws ApiError
     */
    public addCalendarAccountCallback(
        code: string,
        state: string,
        provider?: CalendarProvider,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendars/callback',
            query: {
                'code': code,
                'state': state,
                'provider': provider,
            },
        });
    }
    /**
     * Add Microsoft Calendar Account Callback
     * Add Microsoft Calendar Account Callback
     * @param code
     * @param state
     * @returns any
     * @throws ApiError
     */
    public addMicrosoftCalendarAccountCallback(
        code: string,
        state: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/calendars/microsoft/callback',
            query: {
                'code': code,
                'state': state,
            },
        });
    }
    /**
     * Add Microsoft Online Meeting Account Callback
     * Add Microsoft Online Meeting Account Callback
     * @param code
     * @param state
     * @returns any
     * @throws ApiError
     */
    public addMicrosoftOnlineMeetingAccountCallback(
        code: string,
        state: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/online-meetings/microsoft/callback',
            query: {
                'code': code,
                'state': state,
            },
        });
    }
    /**
     * Add Microsoft Online Meeting Account Callback
     * Add Microsoft Online Meeting Account Callback
     * @param code
     * @param state
     * @returns any
     * @throws ApiError
     */
    public addMicrosoftOnlineMeetingAccountCallback1(
        code: string,
        state: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/online-meetings/zoom/callback',
            query: {
                'code': code,
                'state': state,
            },
        });
    }
    /**
     * @param xZmSignature
     * @param xZmRequestTimestamp
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public publicOnlineMeetingsControllerZoomWebhook(
        xZmSignature: string,
        xZmRequestTimestamp: string,
        requestBody: ZoomWebhookEvent,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/online-meetings/zoom/webhook',
            headers: {
                'x-zm-signature': xZmSignature,
                'x-zm-request-timestamp': xZmRequestTimestamp,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
