/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PagniatedMinimalPublicTherapistWithCity } from '../models/PagniatedMinimalPublicTherapistWithCity';
import type { PublicTherapist } from '../models/PublicTherapist';
import type { TherapistCharacteristicEntity } from '../models/TherapistCharacteristicEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TherapistsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Search for therapists. Either cityZip or CityName or none can be provided.
     * Search for therapists
     * @param order Order of sorting
     * @param cornerstone Cornerstones the therapist offers
     * @param service Services the therapist offers
     * @param billing Billing methods of the therapist
     * @param procedure Procedures the therapist offers
     * @param admission Admissions of the therapist
     * @param acceptsNewClients Accepts new clients
     * @param gender Gender of the therapist
     * @param language Language the therapist speaks
     * @param kilometerRadius Radius in kilometers
     * @param cityName City Name
     * @param cityZip City ZIP
     * @param sortBy Attribute to sort by
     * @param page
     * @param pageSize
     * @returns PagniatedMinimalPublicTherapistWithCity List of therapists
     * @throws ApiError
     */
    public searchTherapists(
        order?: 'ASC' | 'DESC',
        cornerstone?: Array<string>,
        service?: Array<string>,
        billing?: Array<string>,
        procedure?: Array<string>,
        admission?: Array<string>,
        acceptsNewClients?: Array<'no' | 'yes' | 'wait1To2Weeks' | 'wait2To4Weeks' | 'wait2Months' | 'wait3Months' | 'wait6Months' | 'wait1year'>,
        gender?: Array<'male' | 'female' | 'diverse' | 'not_set' | 'deleted'>,
        language?: Array<'de' | 'tr' | 'pl' | 'ru' | 'ar' | 'ku' | 'en' | 'it' | 'el' | 'ro' | 'sr' | 'hr' | 'es' | 'pt' | 'bg' | 'nl' | 'fr' | 'vi' | 'fa' | 'zh' | 'uk' | 'sq' | 'hu' | 'ps' | 'bs' | 'hi' | 'ur' | 'da' | 'sv' | 'bn' | 'ta' | 'fi' | 'sk' | 'cs' | 'mk' | 'th' | 'lt' | 'so' | 'he' | 'ml' | 'az' | 'am' | 'hy' | 'ti' | 'ka' | 'kk' | 'si' | 'sl' | 'lv' | 'et'>,
        kilometerRadius?: number,
        cityName?: string,
        cityZip?: string,
        sortBy?: string,
        page?: number,
        pageSize?: number,
    ): CancelablePromise<PagniatedMinimalPublicTherapistWithCity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/search',
            query: {
                'order': order,
                'cornerstone': cornerstone,
                'service': service,
                'billing': billing,
                'procedure': procedure,
                'admission': admission,
                'acceptsNewClients': acceptsNewClients,
                'gender': gender,
                'language': language,
                'kilometerRadius': kilometerRadius,
                'cityName': cityName,
                'cityZip': cityZip,
                'sortBy': sortBy,
                'page': page,
                'pageSize': pageSize,
            },
        });
    }
    /**
     * Get all characteristics for a specific category
     * Get characteristics
     * @param category Category of the characteristics
     * @returns TherapistCharacteristicEntity List of characteristics
     * @throws ApiError
     */
    public getCharacteristics(
        category: 'admissions' | 'procedures' | 'billing' | 'services' | 'cornerstones' | 'excludedCornerstones',
    ): CancelablePromise<Array<TherapistCharacteristicEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/characteristics',
            query: {
                'category': category,
            },
        });
    }
    /**
     * Get all languages
     * Get languages
     * @returns string List of languages
     * @throws ApiError
     */
    public getLanguages(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/languages',
        });
    }
    /**
     * Get Therapist by Slug
     * Get Therapist by Slug
     * @param slug
     * @returns PublicTherapist
     * @throws ApiError
     */
    public getPublicTherapist(
        slug: string,
    ): CancelablePromise<PublicTherapist> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/{slug}',
            path: {
                'slug': slug,
            },
        });
    }
    /**
     * Get Therapist by Id
     * Get Therapist by Id
     * @param id
     * @returns PublicTherapist
     * @throws ApiError
     */
    public getPublicTherapistById(
        id: number,
    ): CancelablePromise<PublicTherapist> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/therapists/id/{id}',
            path: {
                'id': id,
            },
        });
    }
}
