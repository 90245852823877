/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeatureComment } from './FeatureComment';
import type { FeatureUser } from './FeatureUser';
export type Feature = {
    createdAt: string;
    id: number;
    title: string;
    slug: string;
    description: string;
    isAnonymous: boolean;
    isOwnFeature: boolean;
    numberOfVotes: number;
    userHasVotedForFeature: boolean;
    status: Feature.status;
    user: FeatureUser | null;
    comments: Array<FeatureComment>;
};
export namespace Feature {
    export enum status {
        NEW = 'new',
        PLANNED = 'planned',
        IN_PROGRESS = 'in_progress',
        DONE = 'done',
    }
}

