'use client'

import COMPONENT_TEXTS from '@tebuto/translations'
import { useRouter } from 'next/navigation'
import { Menu, MenuEntry } from '../Menu'

export default function Navbar() {
    const router = useRouter()

    const menuItems: MenuEntry[] = [
        {
            name: COMPONENT_TEXTS.layout.client.settings,
            href: '/einstellungen'
        },
        {
            name: COMPONENT_TEXTS.layout.client.logout,
            onClick: () => router.push(`${process.env.NEXT_PUBLIC_CLIENTS_API_URL}/auth/logout`)
        }
    ]

    return <Menu rightItems={menuItems} leftItems={[]} baseUrl={`${process.env.NEXT_PUBLIC_CLIENTS_BASE_URL}`} />
}
